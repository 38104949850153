import axios, { AxiosError } from 'axios';
import BffApiResponse from '../models/BffApiResponse';
import ApiResponse from '../models/ApiResponse';
import AlertMessage, { Severity } from 'models/AlertMessage';
import { toast } from 'react-toastify';

export const formattingTitaskBffResponseErrors = (
  err: any | AxiosError,
  ignoreStatuses: number[] = [],
  disableToast = false,
  additionalData: any = undefined
): BffApiResponse => {
  try {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        return new BffApiResponse(
          false,
          null,
          500,
          'Something went wrong. (code: 101)',
          additionalData
        );
      } else if (err.response.data?.data) {
        console.log('1', err);
        if (
          ignoreStatuses.length > 0 &&
          ignoreStatuses.includes(err.response.status)
        ) {
          return new BffApiResponse(true, null, 200, '', additionalData);
        }

        if (!disableToast) {
          if ([401].includes(err.response.status)) {
            console.log('1.2.1', err);
            if (window.location.pathname !== '/') {
              toast.error(
                `¡Ups! Parece que tu sesión ha expirado. Por favor, inicia sesión nuevamente para continuar.`,
                {
                  position: 'top-right',
                  autoClose: 3000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: 'colored',
                  onClose: () => {
                    window.location.href = '/';
                  },
                }
              );
            }
          } else {
            toast.error(
              err.response.data?.data?.message ||
                err.response.data?.message ||
                'Internal Server Error [2]',
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'colored',
              }
            );
          }
        }

        return new BffApiResponse(
          false,
          null,
          err.response.status,
          err.response.data.data.message || 'Something went wrong. (code: 202)',
          additionalData
        );
      } else {
        console.error('Error with wrong structure (Backend)', err);

        const errorMessage = 'Something went wrong. (code: 201)';

        if (!disableToast) {
          toast.error(errorMessage, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'colored',
          });
        }

        return new BffApiResponse(
          false,
          null,
          err.response.status,
          errorMessage,
          additionalData
        );
      }
    }
    console.error('Error with wrong structure (Frontend)', err);

    const errorMessage = 'Something went wrong. (code: 301)';

    if (!disableToast) {
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    return new BffApiResponse(
      false,
      null,
      500,
      'Something went wrong. (code: 301)',
      additionalData
    );
  } catch (ex: any) {
    console.log('error 500', ex);

    const errorMessage = 'Something went wrong. (code: 901)';

    if (!disableToast) {
      toast.error(errorMessage, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'colored',
      });
    }

    return new BffApiResponse(false, null, 500, errorMessage, additionalData);
  }
};

export const formattingAxiosResponseErrorsFromIntegrationsApi = (
  err: any | AxiosError
): BffApiResponse => {
  try {
    if (axios.isAxiosError(err)) {
      if (!err.response) {
        return new BffApiResponse(
          false,
          null,
          500,
          'Something went wrong. (code: 101)'
        );
      } else if (err.response?.status === 404) {
        console.log('404 =>', err.response?.data?.message?.message);
        return new BffApiResponse(true, null);
      } else if (err.response?.status === 500) {
        return new BffApiResponse(
          false,
          null,
          500,
          'Something went wrong. (code: 102)'
        );
      } else {
        const message = (err.response?.data?.message?.message || '').replaceAll(
          '.',
          ''
        );

        return new BffApiResponse(
          false,
          null,
          err.response.status,
          message || 'Something went wrong. (code: 202)'
        );
      }
    }

    return new BffApiResponse(
      false,
      null,
      500,
      'Something went wrong. (code: 301)'
    );
  } catch (ex: any) {
    console.error('error 500', ex);

    return new BffApiResponse(
      false,
      null,
      500,
      'Something went wrong. (code: 301)'
    );
  }
};

export const formattingErrorResponseToAlertBody = (
  apiResponse: ApiResponse
): AlertMessage => {
  return {
    open: true,
    message:
      (apiResponse.typeError ? apiResponse.typeError : apiResponse.error) || '',
    translate: !!apiResponse.typeError,
    severity: apiResponse.severity || Severity.WARNING,
  };
};
