import React, { Fragment } from 'react';
import { TextField } from '@material-ui/core';
import Autocomplete from '@mui/material/Autocomplete';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import './Select.scss';
import ErrorMessage from 'components/Atoms/ErrorMessage/ErrorMessage';

export interface DataInterface {
  text: string;
  value: string;
  idState?: number;
  idCountry?: number;
  roleId?: number;
  taskId?: number;
  companyId?: number;
}
interface SelectInterface {
  disabled?: boolean;
  className?: string;
  data: DataInterface[];
  errors?: any;
  errorClassName?: string;
  label?: string;
  labelColor?: string;
  name: string;
  onChange?: (
    event: any,
    newValue?: DataInterface | string | undefined
  ) => void;
  placeholder?: string;
  required?: boolean;
  value?: any;
  variant?: 'outlined' | 'standard' | 'filled' | undefined;
  isValueNumeric?: boolean;
  multiple?: boolean;
}

const Select = ({
  disabled = false,
  className = '',
  data,
  errors = null,
  errorClassName = '',
  label,
  labelColor,
  name,
  onChange,
  placeholder = '',
  required = true,
  value,
  variant = 'outlined',
  isValueNumeric = false,
  multiple = false,
}: SelectInterface) => {
  const selectOptions = data.filter((row) => row.value !== '');
  if (className) className = ` ${className}`;

  if (typeof value === 'number') {
    if (!isValueNumeric) value = `${value}`;
  }
  // else {
  //   if (value) {
  //     if (value.text) value = value.value;
  //     else {
  //       const findValue = data.find(
  //         (option) => option.value.toLowerCase() === value.toLowerCase()
  //       );
  //       if (findValue) value = findValue;
  //     }
  //   }
  // }

  return (
    <Fragment>
      {label && (
        <p
          className="textfield__label"
          style={{ color: labelColor }}
        >{`${label}`}</p>
      )}
      <Autocomplete
        multiple={multiple}
        disabled={disabled}
        className="autocomplete__main"
        id={name}
        options={selectOptions}
        getOptionLabel={(option) => {
          if (option && typeof option === 'number' && data) {
            const numberTextLabel = data.find(
              (row: any) => row && row.value === option
            );
            return numberTextLabel ? numberTextLabel.text : '';
          }
          return option.text ?? '';
        }}
        fullWidth
        onChange={(e, newValue: string) => {
          if (onChange) return onChange(e, newValue);
        }}
        value={value}
        renderInput={(params) => (
          <TextField
            {...params}
            className={`textfield__main inner__input ${className}`}
            required={required}
            name={name}
            placeholder={placeholder}
            variant={variant}
          />
        )}
        popupIcon={<ExpandMoreIcon />}
      />
      {errors && errors[name] && (
        <ErrorMessage
          className={errorClassName}
          // message={errors[name] ? errors[name] : 'El valor es requerido'}
        />
      )}
    </Fragment>
  );
};

export default Select;
