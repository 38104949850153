import React, { Fragment } from 'react';
import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import IconButton from '../../Atoms/IconButton/IconButton';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';

import './Table.scss';

interface FooterTableInterface {
  handleChangePage: (
    _event: React.MouseEvent<HTMLButtonElement | HTMLDivElement> | null,
    newPage: number
  ) => void;
  handleRowsPerPageChange: (event: SelectChangeEvent) => void;
  page: number;
  rows: any;
  rowsData: any;
  rowsPerPage: number;
}

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement> | null,
    newPage: number
  ) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const totalPages = count / rowsPerPage;
  const handleBackButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    onPageChange(event, page + 1);
  };

  const showPagesNumber = () => {
    const pages = [];
    for (let index = 0; index < totalPages; index++) {
      pages.push(index);
    }
    return pages;
  };

  const changePagination = (
    event: React.MouseEvent<HTMLDivElement>,
    pageNumber: number
  ) => {
    onPageChange(event, pageNumber);
  };

  return (
    <Fragment>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        className={`table-button${page === 0 ? ' table-button--disabled' : ''}`}
        ariaLabel="previous page"
      >
        <ArrowLeftIcon />
      </IconButton>
      <div className="pages__main">
        {showPagesNumber().map(
          (pageNumber) =>
            (pageNumber <= 3 ||
              (pageNumber === 4 && page < pageNumber) ||
              (pageNumber > 3 && pageNumber === page)) && (
              <div
                key={pageNumber}
                className={`pages__content${
                  pageNumber === page ? ' pages__content--active' : ''
                }`}
                onClick={(e: React.MouseEvent<HTMLDivElement>) =>
                  changePagination(e, pageNumber)
                }
              >
                <p className="pages__number">{pageNumber + 1}</p>
              </div>
            )
        )}
      </div>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        className={`table-button${
          page >= Math.ceil(count / rowsPerPage) - 1
            ? ' table-button--disabled'
            : ''
        }`}
        ariaLabel="next page"
      >
        <ArrowRightIcon />
      </IconButton>
    </Fragment>
  );
}

const FooterTable = ({
  handleChangePage,
  handleRowsPerPageChange,
  page,
  rows,
  rowsData,
  rowsPerPage,
}: FooterTableInterface) => {
  const getCurrentData = () => {
    const total = (page + 1) * rowsPerPage;
    return total > rowsData.length ? rowsData.length : total;
  };

  return (
    <div style={{ position: 'relative' }}>
      <div className="footer__main">
        <div className="left-side">
          <p>
            Mostrando {getCurrentData()} de {rowsData.length} registros
          </p>
        </div>
        <div className="right-side">
          <p>Mostrar</p>
          <FormControl fullWidth>
            <Select
              className="row-per-page-select"
              labelId="row-per-page-label"
              id="row-per-page-select"
              value={rowsPerPage.toString()}
              label=""
              onChange={handleRowsPerPageChange}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={30}>30</MenuItem>
            </Select>
          </FormControl>
          <TablePaginationActions
            count={rowsData.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
          />
        </div>
      </div>
    </div>
  );
};

export default FooterTable;
