import React, { useEffect, useState } from 'react';
import IconButton from 'components/Atoms/IconButton/IconButton';
import { ReactComponent as FilterIcon } from 'assets/images/icons/filter.svg';
import { ReactComponent as SearchIcon } from 'assets/images/icons/search.svg';
import { ColumnInterface } from './Table.utils';
import { MultipleDataInterface } from 'components/Atoms/MultipleSelect/MultipleSelect';
import Accordion from 'components/Atoms/Accordion/Accordion';
import MultiCheckbox from 'components/Atoms/Multicheckbox/Multicheckbox';
import { Button } from 'components/Atoms/Button/Button';
import { TextField } from '@mui/material';
import RadioButton from 'components/Atoms/RadioButton/RadioButton';
import DatePicker from 'components/Molecules/Datepicker/Datepicker';

interface FilterOptionInterface {
  columns: ColumnInterface[];
  filter: any;
  filterColumns: string[];
  filterHandleClick?: () => void;
  getDataForSelect: (
    prop: string,
    search?: string | null,
    init?: boolean,
    dates?: any
  ) => MultipleDataInterface[];
  isFilterOpen: boolean;
  itemsForSelect: string[];
  changeItemsForSelect: (newItems: string[]) => void;
  changeFilter: (newFilter: any) => void;
  isSimple?: boolean;
  type?: 'checkbox' | 'radio' | 'date';
  saveFilter?: () => void;
  cleanFilter?: boolean;
  currentRows?: any[];
}

const FilterOption = ({
  columns,
  filterColumns,
  isFilterOpen,
  getDataForSelect,
  filterHandleClick,
  itemsForSelect,
  changeItemsForSelect,
  changeFilter,
  isSimple = false,
  type = 'radio',
  saveFilter,
  cleanFilter = false,
  currentRows = [],
}: FilterOptionInterface) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectData, setSelectData] = useState<any>([]);
  const [filterTemp, setFilterTemp] = useState<any>({});

  useEffect(() => {
    if (selectData.length === 0 && !filterHandleClick) {
      const result = getDataForSelect('');
      setSelectData(result);
    }
    if (selectData.length === 0 && filterHandleClick) {
      const data: any = {};
      columns.forEach(({ prop }) => {
        const columnData = getDataForSelect(prop, null, true);
        if (columnData.length > 0) {
          data[prop] = getDataForSelect(prop, null, true);
        }
      });
      setSelectData(data);
    }
  }, [selectData]);

  useEffect(() => {
    if (cleanFilter) cancelFilterOnClick();
  }, [cleanFilter]);

  useEffect(() => {
    console.log('amanecer', getDataForSelect(''), currentRows);
    setSelectData(getDataForSelect(''));
  }, [currentRows]);

  const removeDuplicates = (array: string[]) => {
    const removeDuplicates = array.filter(
      (value, index, self) => index === self.findIndex((t) => t === value)
    );
    return Array.from(removeDuplicates);
  };

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    prop: string,
    fieldType = 'radio'
  ) => {
    if (event.target.checked) {
      const newItems: string[] = [...itemsForSelect, event.target.name];
      changeItemsForSelect(newItems);
      const value =
        fieldType === 'radio' ? event.target.value : event.target.name;
      if (Object.keys(filterTemp).length > 0) {
        if (fieldType === 'radio' && filterTemp[prop]) {
          filterTemp[prop] = [];
        }
        if (filterTemp[prop]) {
          const valueExists = filterTemp[prop].findIndex(
            (el: string) => el === value
          );
          if (valueExists) {
            const newFilterItem = filterTemp[prop];
            newFilterItem.push(value);
            const newFilter = {
              ...filterTemp,
              [prop]: removeDuplicates(newFilterItem),
            };
            setFilterTemp(newFilter);
          }
        } else {
          /*
          let valueExists = [];
          //valueExists = filterTemp.findIndex((el: string) => el === value);
          if (valueExists) {
            const newFilterItem = filterTemp;
            newFilterItem.push(value);
            const newFilter = {
              ...filterTemp,
              [prop]: newFilterItem,
            };
            setFilterTemp(newFilter);
          }*/
          const newFilter = {
            ...filterTemp,
            [prop]: [value],
          };
          setFilterTemp(newFilter);
        }
      } else {
        const newFilter = {
          ...filterTemp,
          [prop]: [value],
        };
        setFilterTemp(newFilter);
      }
    } else {
      const newItems: string[] = itemsForSelect.filter(
        (item: string) => item !== event.target.name
      );

      changeItemsForSelect(newItems);
      if (filterTemp.length > 0) {
        const newFilterItem = filterTemp[prop].filter(
          (item: string) => item !== event.target.name
        );
        const newFilter = {
          ...filterTemp,
          [prop]: newFilterItem,
        };
        setFilterTemp(newFilter);
      } else {
        setFilterTemp({});
      }
    }
  };

  const datesOnChange = (
    startDate: string | Date | null,
    endDate: string | Date | null
  ) => {
    let prop = '';
    const column = columns.filter(
      (column) => column.prop.includes('date') || column.type === 'date'
    );
    if (column.length > 0) prop = column[0].prop;
    getDataForSelect(prop, '', false, { startDate, endDate });
    const newFilter = {
      ...filterTemp,
      [prop]: { startDate, endDate },
    };
    setFilterTemp(newFilter);
  };

  const cancelFilterOnClick = () => {
    setStartDate(null);
    setEndDate(null);
    setFilterTemp({});
    changeFilter({});
    changeItemsForSelect([]);
  };

  const saveFilterOnClick = () => {
    changeFilter(filterTemp);
    if (saveFilter !== undefined) saveFilter();
  };

  const searchOnChange = (event: any) => {
    setSelectData(getDataForSelect(event.target.value));
  };

  const searchItemOnChange = (event: any, prop: string, init = false) => {
    setSelectData([]);
    if (prop !== '') {
      const search = getDataForSelect(
        prop,
        init ? event : event.target.value,
        init
      );
      const newFilterData = {
        ...selectData,
      };
      newFilterData[prop] = [...search];
      setSelectData(newFilterData);
    }
  };

  return (
    <>
      {filterHandleClick && (
        <IconButton className={`filter-button`} onClick={filterHandleClick}>
          <FilterIcon />
        </IconButton>
      )}
      <div
        id="filter-menu"
        className={`filter-menu${isFilterOpen ? ' filter-menu--open' : ''}${
          !filterHandleClick ? ' filter-menu--no-handle' : ''
        }`}
      >
        {filterHandleClick ? (
          <div className="label-filter-menu-item">
            <p>Filtros</p>
          </div>
        ) : (
          <div className="search__main">
            <SearchIcon />
            <TextField
              id="search-field"
              placeholder="Buscar..."
              variant="standard"
              onChange={searchOnChange}
            />
          </div>
        )}
        {columns.map(({ label, prop }) => {
          if (
            filterColumns.includes(prop) &&
            !['status'].includes(prop) &&
            !prop.includes('date') &&
            type === 'checkbox'
          ) {
            if (filterHandleClick) {
              return (
                <div className="filter-menu-item" key={label}>
                  <Accordion
                    items={[
                      {
                        label,
                        children: (
                          <>
                            <div className="search__main">
                              <SearchIcon />
                              <TextField
                                id="search-field"
                                placeholder={`Buscar ${label}...`}
                                variant="standard"
                                onChange={(event: any) =>
                                  searchItemOnChange(event, prop)
                                }
                              />
                            </div>
                            {selectData[prop]?.length > 0 && (
                              <MultiCheckbox
                                items={selectData[prop]}
                                handleChange={(
                                  event: React.ChangeEvent<HTMLInputElement>
                                ) => handleChange(event, prop)}
                                itemsForSelect={itemsForSelect}
                              />
                            )}
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              );
            }
            return (
              <div className="filter-menu-item" key={label}>
                <Accordion
                  items={[
                    {
                      label,
                      children: (
                        <>
                          <MultiCheckbox
                            items={isSimple ? selectData : selectData[prop]}
                            handleChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => handleChange(event, prop)}
                            itemsForSelect={itemsForSelect}
                          />
                        </>
                      ),
                    },
                  ]}
                  startExpanded
                />
              </div>
            );
          }
          if (
            filterColumns.includes(prop) &&
            !prop.includes('date') &&
            type === 'radio'
          ) {
            if (filterHandleClick) {
              return (
                <div className="filter-menu-item" key={label}>
                  <Accordion
                    items={[
                      {
                        label,
                        children: (
                          <>
                            <div className="search__main">
                              <SearchIcon />
                              <TextField
                                id="search-field"
                                placeholder={`Buscar ${label}...`}
                                variant="standard"
                                onChange={(event: any) =>
                                  searchItemOnChange(event, prop)
                                }
                              />
                            </div>
                            {selectData[prop]?.length > 0 && (
                              <>
                                <RadioButton
                                  items={selectData[prop]}
                                  handleChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => handleChange(event, prop, 'radio')}
                                  itemsForSelect={itemsForSelect}
                                />
                              </>
                            )}
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              );
            }
            return (
              <div className="filter-menu-item" key={label}>
                <Accordion
                  items={[
                    {
                      label,
                      children: (
                        <>
                          <RadioButton
                            items={isSimple ? selectData : selectData[prop]}
                            handleChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => handleChange(event, prop)}
                            itemsForSelect={itemsForSelect}
                          />
                        </>
                      ),
                    },
                  ]}
                  startExpanded
                />
              </div>
            );
          }
          if (
            filterColumns.includes(prop) &&
            (prop.includes('date') || type === 'date')
          ) {
            if (filterHandleClick) {
              const startOnChange = (date: Date | null) => {
                setStartDate(date);
              };
              const endOnChange = (date: Date | null) => {
                setEndDate(date);
                datesOnChange(startDate, date);
              };
              return (
                <div className="filter-menu-item" key={label}>
                  <Accordion
                    items={[
                      {
                        label,
                        children: (
                          <>
                            <div className="dates__main">
                              <div className="dates__col">
                                <DatePicker
                                  label=""
                                  value={startDate}
                                  onChange={startOnChange}
                                />
                              </div>
                              <div className="dates__col">
                                <DatePicker
                                  label=""
                                  value={endDate}
                                  onChange={endOnChange}
                                />
                              </div>
                            </div>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>
              );
            }
            return (
              <div className="filter-menu-item" key={label}>
                <Accordion
                  items={[
                    {
                      label,
                      children: (
                        <>
                          <RadioButton
                            items={isSimple ? selectData : selectData[prop]}
                            handleChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => handleChange(event, prop)}
                            itemsForSelect={itemsForSelect}
                          />
                        </>
                      ),
                    },
                  ]}
                  startExpanded
                />
              </div>
            );
          }
          return null;
        })}
        <div className="filter-footer">
          <Button
            onClick={cancelFilterOnClick}
            text={'Borrar'}
            type="primary"
            variant="outlined"
            border="square"
            large
          />
          <Button
            onClick={saveFilterOnClick}
            text={'Aplicar'}
            type="primary"
            variant="contained"
            border="square"
            large
          />
        </div>
      </div>
    </>
  );
};

export default FilterOption;
