import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ColumnInterface } from 'components/Organisms/Table/Table.utils';
import AddIcon from '@mui/icons-material/Add';
import { Button } from 'components/Atoms/Button/Button';
import { DataInterface } from 'components/Atoms/Select/Select';

export interface RowI {
  name: string;
  role: string;
  charge: string;
  status: string;
  rowId: string;
}

export const columns: ColumnInterface[] = [
  {
    id: 'name',
    prop: 'name',
    sort: false,
    label: 'Nombre y Apellido',
  },
  {
    id: 'role',
    prop: 'role',
    sort: false,
    label: 'Rol',
  },
  {
    id: 'charge',
    prop: 'charge',
    sort: false,
    label: 'Puesto',
  },
  {
    id: 'status',
    prop: 'status',
    sort: false,
    label: 'Estado',
    type: 'status',
  },
  {
    id: 'rowId',
    prop: 'rowId',
    label: 'Id',
    hiddenColumn: true,
  },
];

export const OptionsTable = () => {
  const history = useNavigate();
  const createUsersOnClick = () => {
    history('/users/create');
  };

  return (
    <div className="users-options">
      <Button
        onClick={createUsersOnClick}
        text={'Crear Usuario'}
        type="primary"
        startIcon={<AddIcon />}
        variant="contained"
        border="square"
      />
    </div>
  );
};

export const transformDataForSelect = (data: any) => {
  const newData: DataInterface[] = [];

  data.forEach((row: any) => {
    const newRow = {
      text: row.titleEs,
      value: row.id,
      roleId: row.id,
    };
    if (row.roleId) {
      newRow.roleId = row.roleId;
    }
    newData.push(newRow);
  });

  return newData;
};

export const transformTasksDataForSelect = (data: any) => {
  const newData: DataInterface[] = [];

  data.forEach((row: any) => {
    const newRow = {
      text: row.name,
      value: row.id,
      taskId: 0,
    };
    if (row.taskId) {
      newRow.taskId = row.taskId;
    }
    newData.push(newRow);
  });

  return newData;
};

export const getDataForTable = (data: any) => {
  if (data.length > 0) {
    const newDataForTable: RowI[] = [];
    data = data.sort((a: any, b: any) => {
      return b.id - a.id;
    });
    data.forEach((row: any) => {
      newDataForTable.push({
        name: `${row.name} ${row.lastname}`,
        role: row.roleTitleEs,
        charge: row.positionTitleEs,
        status: row.disabledAt === null ? (row.isVerified ? '1' : '2') : '0',
        rowId: row.id,
      });
    });
    return newDataForTable;
  }
  return [];
};

export interface ITasks {
  number: number;
  task_name: string;
  client: string;
  status: boolean;
}

export interface ITasksStores {
  number: number;
  task_name: string;
  client: string;
  stores: number;
}

export const taskStoreColumns: ColumnInterface[] = [
  {
    id: 'number',
    prop: 'number',
    sort: false,
    label: 'N°',
    type: 'numeric_list',
  },
  {
    id: 'task_name',
    prop: 'task_name',
    sort: false,
    label: 'Nombre de Tarea',
  },
  {
    id: 'client',
    prop: 'client',
    sort: false,
    label: 'Cliente',
  },
  {
    id: 'stores',
    prop: 'stores',
    sort: false,
    label: 'Tiendas',
    type: 'stores',
  },
];
