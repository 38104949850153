import React, {
  forwardRef,
  Fragment,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {
  CustomSimpleTableRef,
  SimpleTableSchema,
} from 'components-kit/Organisms/SimpleTable/SimpleTable.types';
import Paper from '@mui/material/Paper';
import MaterialTable from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import SimpleFooterTable from 'components-kit/Organisms/SimpleTable/SimpleFooterTable';
import { Checkbox, SelectChangeEvent } from '@mui/material';
import SimpleTableHeader from 'components-kit/Organisms/SimpleTable/SimpleTableHeader';
import SimpleActionMenuList from 'components-kit/Organisms/SimpleTable/SimpleActionMenuList';

import './SimpleTable.scss';

const INITIAL_CURRENT_PAGE = 1;
const AVAILABLE_PAGE_SIZES = [5, 10, 15, 20, 25, 50, 100];
const CELL_HEIGHT = 50;

const SimpleTable = forwardRef<
  CustomSimpleTableRef<any>,
  SimpleTableSchema<any>
>((props, ref): JSX.Element => {
  const {
    columns,
    request,
    initialDefaultPageSize = 5,
    loading = false,
    minWidth = 650,
    grayColor = false,
    emptyText = 'No se encontraron datos',
    selected,
    setSelected,
    id = 'id',
    singularSelectedItemTitle,
    pluralSelectedItemTitle,
    singleActionsTable = [],
    multiActionsTable = [],
    actionsColumnName = 'Acciones',
    actionsList = [],
    isDisabledSelectedCheckboxCondition,
    forcedDisabledAllSelectedCheckbox = false,
    actionListReferentialWidth,
  } = props;

  const [pageSize, setPageSize] = useState(initialDefaultPageSize);
  const [currentPage, setCurrentPage] = useState(INITIAL_CURRENT_PAGE);
  const [totalRows, setTotalRows] = useState<number>(0);
  const [data, setData] = useState<any[]>([]);
  const [availablePageSizes, setAvailablePageSizes] =
    useState<number[]>(AVAILABLE_PAGE_SIZES);
  const emptyRows = pageSize;
  const numSelected = selected ? selected.length : 0;

  useEffect(() => {
    const availablePagesSizeRaw = Array.from(
      new Set(availablePageSizes.concat([pageSize]))
    ).sort((a, b) => a - b);

    setAvailablePageSizes(availablePagesSizeRaw);
    localFetchData();
  }, []);

  useEffect(() => {
    setNewPage(1);
  }, [pageSize]);

  const localFetchData = async (page?: number) => {
    const localPage = page || currentPage;

    if (page) {
      setCurrentPage(page);
    }

    const dataRawSchemaResponse = await request(pageSize, localPage);

    setTotalRows(dataRawSchemaResponse.totalRows);
    setData(dataRawSchemaResponse.items);
  };

  const setNewPage = async (newPage: number) => {
    if (newPage !== currentPage) {
      setCurrentPage(newPage);

      const dataRawSchemaResponse = await request(pageSize, newPage);

      setTotalRows(dataRawSchemaResponse.totalRows);
      setData(dataRawSchemaResponse.items);
    }
  };

  const setNewPageSize = async (newPageSize: number) => {
    setPageSize(newPageSize);

    const dataRawSchemaResponse = await request(newPageSize, currentPage);

    setTotalRows(dataRawSchemaResponse.totalRows);
    setData(dataRawSchemaResponse.items);
  };

  const handleChangePage = (
    _event: React.MouseEvent<HTMLButtonElement | HTMLDivElement> | null,
    newPage: number
  ) => {
    setNewPage(newPage);
    if (setSelected && !forcedDisabledAllSelectedCheckbox) {
      setSelected([]);
    }
  };

  const handleRowsPerPageChange = (event: SelectChangeEvent) => {
    setNewPageSize(Number.parseInt(event.target.value));
  };

  useImperativeHandle(ref, () => ({
    fetchData(page?: number) {
      localFetchData(page);
    },
  }));

  const isDisabledConditionalCheckBox = (row: any): boolean => {
    if (isDisabledSelectedCheckboxCondition) {
      return isDisabledSelectedCheckboxCondition(row);
    }
    return false;
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (setSelected) {
      if (event.target.checked) {
        const newSelected = data
          .filter((row: any) => !isDisabledConditionalCheckBox(row))
          .map((row: any) => row[id]);
        setSelected(newSelected);
        return;
      }

      setSelected([]);
    }
  };

  const activeRowCount = data.filter(
    (row: any) => !isDisabledConditionalCheckBox(row)
  ).length;
  const activeSelectedCount = selected
    ? selected.filter((itemId: any) => {
        return data.some(
          (row: any) =>
            row[id] === itemId && !isDisabledConditionalCheckBox(row)
        );
      }).length
    : 0;

  const getSelectedColumnsName = () => {
    if (singularSelectedItemTitle && pluralSelectedItemTitle) {
      return (selected || []).length === 1
        ? singularSelectedItemTitle
        : pluralSelectedItemTitle;
    }

    return (selected || []).length === 1 ? 'item' : 'items';
  };

  return (
    <Fragment>
      {selected &&
        selected.length > 0 &&
        singleActionsTable &&
        multiActionsTable &&
        singleActionsTable.length + multiActionsTable.length > 0 && (
          <SimpleTableHeader
            selected={selected}
            singleActionsTable={singleActionsTable}
            multiActionsTable={multiActionsTable}
            singularItemTitle={singularSelectedItemTitle}
            pluralItemTitle={pluralSelectedItemTitle}
          />
        )}
      <TableContainer
        className={`table__main${grayColor ? ' table__main--gray' : ''}`}
        component={Paper}
      >
        <MaterialTable sx={{ minWidth }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {selected && setSelected && (
                <TableCell align="center" width="100px" key={-1}>
                  <Checkbox
                    color="primary"
                    indeterminate={
                      activeSelectedCount > 0 &&
                      activeSelectedCount < activeRowCount
                    }
                    checked={
                      activeRowCount > 0 &&
                      activeSelectedCount === activeRowCount
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      'aria-label': 'select all desserts',
                    }}
                    disabled={forcedDisabledAllSelectedCheckbox}
                  />
                </TableCell>
              )}
              {columns?.length > 0 &&
                columns.map((column) => (
                  <TableCell
                    key={column.index}
                    size={column.size ?? 'medium'}
                    align={column.align}
                    width={column.width}
                  >
                    {column.title}
                  </TableCell>
                ))}
              {actionsList.length > 0 && (
                <TableCell key={-2} size="medium" align="center">
                  {actionsColumnName}
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading && (
              <TableRow style={{ height: CELL_HEIGHT * data.length }}>
                <TableCell
                  className="empty-table-cell"
                  colSpan={columns.length === 0 ? 1 : columns.length}
                >
                  <b>Cargando...</b>
                </TableCell>
              </TableRow>
            )}

            {!loading && data.length === 0 && emptyText && (
              <TableRow style={{ height: CELL_HEIGHT * emptyRows }}>
                <TableCell
                  className="empty-table-cell"
                  colSpan={columns.length === 0 ? 1 : columns.length}
                >
                  <b>{emptyText}</b>
                </TableCell>
              </TableRow>
            )}

            {!loading &&
              data.map((row: any, index) => {
                return (
                  <TableRow
                    hover
                    tabIndex={-1}
                    key={index}
                    aria-checked={
                      selected && setSelected && selected.includes(row[id])
                    }
                    selected={
                      selected && setSelected && selected.includes(row[id])
                    }
                  >
                    {selected && setSelected && (
                      <TableCell
                        align="center"
                        padding="checkbox"
                        id={`checkbox-table-${index}`}
                      >
                        <Checkbox
                          disabled={isDisabledConditionalCheckBox(row)}
                          color="primary"
                          checked={selected.includes(row[id])}
                          inputProps={{
                            'aria-labelledby': `checkbox-table-${index}`,
                          }}
                          onChange={(event) => {
                            if (event.target.checked) {
                              let localSelected = selected.filter((sl) => {
                                return sl[id] !== row[id];
                              });

                              localSelected = [...localSelected, row[id]];

                              setSelected(localSelected);
                            } else {
                              const localSelected = selected.filter((sl) => {
                                return sl !== row[id];
                              });
                              setSelected(localSelected);
                            }
                          }}
                        />
                      </TableCell>
                    )}

                    {columns.map((column) => (
                      <TableCell
                        key={`${index}-${column.index}`}
                        align={column.align}
                        component="th"
                        scope="row"
                      >
                        {row[column.index]}
                      </TableCell>
                    ))}

                    {actionsList.length > 0 && (
                      <TableCell
                        align="center"
                        padding="checkbox"
                        id={`action-menu-table-${index}`}
                      >
                        <SimpleActionMenuList
                          index={index}
                          row={row}
                          actions={actionsList}
                          actionListReferentialWidth={
                            actionListReferentialWidth
                          }
                        />
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </MaterialTable>
      </TableContainer>

      <SimpleFooterTable
        currentPage={currentPage}
        pageSize={pageSize}
        totalRows={totalRows}
        data={data}
        handleChangePage={handleChangePage}
        handleRowsPerPageChange={handleRowsPerPageChange}
        availablePageSizes={availablePageSizes}
        grayColor={grayColor}
        additionalFooterText={
          singleActionsTable &&
          multiActionsTable &&
          singleActionsTable.length + multiActionsTable.length > 0
            ? undefined
            : selected && selected.length > 0
            ? selected.length === 1
              ? `(1 ${getSelectedColumnsName()} seleccionado)`
              : `(${selected.length} ${getSelectedColumnsName()} seleccionados)`
            : undefined
        }
      />
    </Fragment>
  );
});

SimpleTable.displayName = 'SimpleTable';

export default SimpleTable;
