import React from 'react';

import './Container.scss';
import { Container as MaterialContainer } from '@material-ui/core';
import CircularProgress from '@mui/material/CircularProgress';
import { Backdrop } from '@mui/material';

interface ContainerInterface {
  maxWidth?: false | 'lg' | 'xs' | 'sm' | 'md' | 'xl' | undefined;
  children: any;
  loading?: boolean;
}

export const Container = ({
  maxWidth = 'lg',
  children,
  loading = false,
}: ContainerInterface) => {
  return (
    <MaterialContainer maxWidth={maxWidth}>
      <Backdrop
        open={loading}
        sx={{
          position: 'absolute',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: '#fff',
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {children}
    </MaterialContainer>
  );
};
